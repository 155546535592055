import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: []
};

export const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.items = [...state.items, action.payload.item];
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(item => item.id !== action.payload.id);
    },
    updateItem: (state, action) => {
      state.items = state.items.map(item => ({
        ...item,
        name: item.id === action.payload.id ? action.payload.name : item.name,
        startDate:
          item.id === action.payload.id
            ? action.payload.startDate
            : item.startDate,
        width:
          item.id === action.payload.id ? action.payload.width : item.width,
        color: item.id === action.payload.id ? action.payload.color : item.color
      }));
    }
  }
});

export const selectItems = state => state.items.items;
export const { addItem, removeItem, updateItem } = itemsSlice.actions;
export default itemsSlice.reducer;
