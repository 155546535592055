import { useState, useEffect, useRef } from 'react';
import { DateTime, Interval } from 'luxon';
import { v7 as uuid } from 'uuid';
import { Input, FormGroup, Label, Row, Col, Form, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  addGroup,
  removeGroup,
  updateGroup,
  selectGroupsMapping
} from '../../state/groupsSlice';
import {
  addItem,
  removeItem,
  updateItem,
  selectItems
} from '../../state/itemsSlice';
import { selectConfig } from '../../state/configSlice';
import './Timeline.scss';

function Timeline({ duration, from, to, today, type, timeline }) {
  const [inputGroupId, setInputGroupId] = useState();
  const [inputGroupName, setInputGroupName] = useState('');
  const [inputItemId, setInputItemId] = useState();
  const [inputItemName, setInputItemName] = useState('');
  const [inputItemWidth, setInputItemWidth] = useState(3);
  const [inputItemStartDate, setInputItemStartDate] = useState(3);
  const [inputItemColor, setInputItemColor] = useState('green');
  const [timelineMonths, setTimelineMonths] = useState([]);
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const groups = useSelector(selectGroupsMapping);
  const config = useSelector(selectConfig);
  const dialogItemRef = useRef();
  const dialogGroupRef = useRef();
  const oneDayLength = 100 / timeline.length;

  const onAddGroup = () => {
    dispatch(
      addGroup({
        group: {
          id: uuid(),
          name: '-New Group-',
          children: []
        }
      })
    );
  };

  const onAddItem = groupId => {
    dispatch(
      addItem({
        item: {
          id: uuid(),
          groupId,
          name: '-New Item-',
          startDate: from,
          width: 3,
          color: 'blue'
        }
      })
    );
  };

  const onViewItem = item => {
    try {
      setInputItemId(item.id);
      setInputItemName(item.name);
      setInputItemStartDate(item.startDate);
      setInputItemWidth(item.width);
      setInputItemColor(item.color);
      dialogItemRef.current.showModal();
    } catch (err) {
      console.error(err);
    }
  };

  const onCloseViewItem = () => {
    setInputItemId();
    setInputItemName('');
    setInputItemStartDate('');
    setInputItemWidth(3);
    setInputItemColor('red');
    dialogItemRef.current.close();
  };

  const onSubmitDialogItem = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    dispatch(
      updateItem({
        id: inputItemId,
        name: formData.get('name'),
        startDate: formData.get('startDate'),
        width: parseInt(formData.get('width')),
        color: formData.get('color')
      })
    );
    onCloseViewItem();
  };

  const onDeleteItem = () => {
    if (window.confirm('Delete?')) {
      dispatch(
        removeItem({
          id: inputItemId
        })
      );
      onCloseViewItem();
    }
  };

  const onViewGroup = group => {
    try {
      setInputGroupId(group.id);
      setInputGroupName(group.name);
      dialogGroupRef.current.showModal();
    } catch (err) {
      console.error(err);
    }
  };

  const onCloseViewGroup = () => {
    setInputGroupId();
    setInputGroupName('');
    dialogGroupRef.current.close();
  };

  const onSubmitDialogGroup = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    dispatch(
      updateGroup({
        id: inputGroupId,
        name: formData.get('name')
      })
    );
    onCloseViewGroup();
  };

  const onDeleteGroup = () => {
    if (window.confirm('Delete? Items will be deleted too.')) {
      items
        .filter(item => item.groupId === inputGroupId)
        .forEach(item =>
          dispatch(
            removeItem({
              id: item.id
            })
          )
        );

      dispatch(
        removeGroup({
          id: inputGroupId
        })
      );
      onCloseViewGroup();
    }
  };

  const getItemsLength = (items, itemIndex) => {
    return items.slice(0, itemIndex).reduce((a, item) => {
      const date1 = DateTime.fromISO(from);
      const date2 = DateTime.fromISO(item.startDate);
      const diff = date2.diff(date1, ['days']);
      const margin = diff.days > 0 ? diff.days : 0;

      return a + (margin + item.width);
    }, 0);
  };

  useEffect(() => {
    if (timeline) {
      let months = [];

      for (let i = 0; i < timeline.length; i++) {
        const date = DateTime.fromISO(timeline[i]).toFormat('MMM');

        if (months.find(m => m.name === date)) {
          months = months.map(m => {
            if (m.name === date) {
              m.count += 1;
            }

            return m;
          });
        } else {
          months.push({ name: date, count: 1 });
        }
      }

      setTimelineMonths(months);
    }
  }, [timeline]);

  return (
    <div className="roadmap" id="roadmap">
      <div className="roadmap-timeline">
        <div className="roadmap-row" style={{ width: '100%' }}>
          {timelineMonths.map((date, dateIndex) => (
            <div
              className="roadmap-col roadmap-month"
              key={`roadmap-date-${dateIndex}`}
              style={{
                width: `calc(100% / ${timeline.length} * ${date.count})`
              }}
            >
              <div className="roadmap-cell">{date.name}</div>
            </div>
          ))}
        </div>

        <div className="roadmap-row" style={{ width: '100%' }}>
          {timeline.map((date, dateIndex) => {
            const interval = Interval.fromDateTimes(
              DateTime.fromISO(date),
              DateTime.fromISO(date).plus({ [type]: 1 })
            );

            const isToday =
              (type === 'days' && today === date) ||
              interval.contains(DateTime.fromISO(today));
            const date1 = DateTime.fromISO(today);
            const date2 = DateTime.fromISO(date);
            const diff = date2.diff(date1, ['days']);
            const isYesterday = diff.days < 0;

            return (
              <div
                className={`roadmap-col roadmap-day ${isToday ? 'roadmap-day-today' : isYesterday ? 'roadmap-day-yesterday' : ''}`}
                key={`roadmap-timeline-date-${dateIndex}`}
                style={{ width: `calc(100% / ${timeline.length})` }}
              >
                <div className="roadmap-cell">
                  {DateTime.fromISO(date).toFormat('d')}
                </div>
              </div>
            );
          })}
        </div>

        {groups.map(group => (
          <div key={`roadmap-timeline-group-${group.id}`}>
            <div className="roadmap-timeline-group-name">
              <span onClick={() => onViewGroup(group)} className="btn">
                {group.name}
              </span>
              &nbsp;
              {config.isPreview ? null : (
                <Button
                  type="button"
                  size="sm"
                  color="dark"
                  onClick={() => onAddItem(group.id)}
                  style={{
                    width: '20px',
                    height: '20px',
                    padding: '0',
                    margin: '0',
                    lineHeight: '20px'
                  }}
                >
                  <i className="bi bi-plus-lg" />
                </Button>
              )}
            </div>
            <div
              className="roadmap-row"
              key={`roadmap-timeline-group-items-${group.id}`}
            >
              {group.items.map((item, itemIndex) => {
                const otherItemsLength = getItemsLength(group.items, itemIndex);
                const date1 = DateTime.fromISO(from);
                const date2 = DateTime.fromISO(item.startDate);
                const diff = date2.diff(date1, ['days']);
                const margin = diff.days > 0 ? diff.days - otherItemsLength : 0;
                const marginLeft = margin > 0 ? margin : 0;

                return (
                  <div
                    className="roadmap-col roadmap-child"
                    style={{
                      width: `calc(100% / ${timeline.length} * ${item.width})`,
                      marginLeft: `${marginLeft * oneDayLength}%`
                    }}
                    key={`roadmap-timeline-item-${group.id}-${item.id}`}
                  >
                    <div
                      className={`roadmap-cell roadmap-child roadmap-color-${item.color}`}
                      onClick={() => onViewItem(item)}
                    >
                      <div
                        className={`roadmap-cell-before roadmap-color-${item.color}`}
                      ></div>
                      {item.name} {item.fromIndex} {item.toIndex}
                      <div
                        className={`roadmap-cell-after roadmap-color-${item.color}`}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}

        <div>
          {config.isPreview ? null : (
            <Button type="button" size="sm" color="dark" onClick={onAddGroup}>
              <i className="bi bi-plus-lg" /> Group
            </Button>
          )}
        </div>
      </div>

      <dialog ref={dialogItemRef}>
        <Form onSubmit={onSubmitDialogItem} method="dialog">
          <FormGroup>
            <Label>Name</Label>
            <Input
              key={inputItemId}
              type="text"
              name="name"
              defaultValue={inputItemName}
            />
          </FormGroup>

          <FormGroup>
            <Label>Start date</Label>
            <Input
              key={inputItemId}
              type="date"
              name="startDate"
              defaultValue={inputItemStartDate}
            />
          </FormGroup>

          <FormGroup>
            <Label>Width</Label>
            <Input
              key={inputItemId}
              type="number"
              name="width"
              defaultValue={inputItemWidth}
            />
          </FormGroup>

          <FormGroup>
            <Label>Color</Label>
            <Input
              key={inputItemId}
              type="select"
              name="color"
              defaultValue={inputItemColor}
            >
              <option>green</option>
              <option>red</option>
              <option>blue</option>
              <option>orange</option>
              <option>grey</option>
            </Input>
          </FormGroup>

          <Row>
            <Col className="text-center">
              <Button color="primary">Save</Button>
              &nbsp;
              <Button
                type="button"
                color="secondary"
                onClick={() => onCloseViewItem()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="text-center">
              <Button
                type="button"
                color="danger"
                onClick={() => onDeleteItem()}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Form>
      </dialog>

      <dialog ref={dialogGroupRef}>
        <Form onSubmit={onSubmitDialogGroup} method="dialog">
          <FormGroup>
            <Label>Name</Label>
            <Input
              key={inputGroupId}
              type="text"
              name="name"
              defaultValue={inputGroupName}
            />
          </FormGroup>

          <Row>
            <Col className="text-center">
              <Button color="primary">Save</Button>
              &nbsp;
              <Button
                type="button"
                color="secondary"
                onClick={() => onCloseViewGroup()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="text-center">
              <Button
                type="button"
                color="danger"
                onClick={() => onDeleteGroup()}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Form>
      </dialog>
    </div>
  );
}

export default Timeline;
