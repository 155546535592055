import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Button } from 'reactstrap';
import html2canvas from 'html2canvas';
import { useSelector, useDispatch } from 'react-redux';
import { setConfig, selectConfig } from '../../state/configSlice';
import './TopBar.scss';

function TopBar() {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);

  const capture = () => {
    const roadmap = document.getElementById('roadmap');

    html2canvas(roadmap, {
      useCORS: true,
      allowTaint: true,
      letterRendering: true,
      onrendered: function (canvas) {
        const ctx = canvas.getContext('2d');
        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
      }
    }).then(function (canvas) {
      const link = document.createElement('a');
      link.setAttribute('download', 'roadmap.png');
      link.setAttribute(
        'href',
        canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      );
      link.click();
    });
  };

  return (
    <div>
      <Navbar className="topbar">
        <NavbarBrand href="/">ROADMAP</NavbarBrand>

        <Nav className="mw-auto">
          <NavItem>
            <NavLink
              onClick={() =>
                dispatch(setConfig({ isPreview: !config.isPreview }))
              }
              className="text-dark btn"
            >
              <i
                className={`bi bi-toggle-${config.isPreview ? 'on' : 'off'}`}
              ></i>
              &nbsp; Preview
            </NavLink>
          </NavItem>
          <NavItem>
            <Button onClick={capture} color="dark">
              Capture
            </Button>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
}

export default TopBar;
